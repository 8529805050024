import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import TextError from './TextError';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';
import '../style/Form.scss';

const initialValues = {
  name: '',
  email: '',
  nachricht: '',
  probestunde: '',
  teamtraining: '',
  recording: '',
};

// Yup
const validationSchema = Yup.object({
  name: Yup.string().required('Pflichfeld'),
  email: Yup.string()
    .email('Bitte geben Sie eine gültige E-Mail Adresse an')
    .required('Pflichfeld'),
  nachricht: Yup.string().required('Pflichfeld'),
});

const Result = () => {
  return (
    <div className="successMessage">
      Vielen Dank!<br></br>
      Ich melde mich schnellst möglich
    </div>
  );
};

const ContactForm = () => {
  const [result, showResult] = useState(false);

  //emailjs

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE,
        process.env.GATSBY_EMAILJS_TEMPLATE,
        e.target,
        process.env.GATSBY_EMAILJS_USER
      )
      .then(
        (result) => {
          console.log(result.status, result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };

  const onSubmit = { sendEmail };

  // hide result
  setTimeout(() => {
    showResult(false);
  }, 5000);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form className="center" onSubmit={sendEmail}>
        <div className="form-control">
          <Field
            type="text"
            id="name"
            name="name"
            as={TextField}
            variant="outlined"
            color="primary"
            label="Name"
            fullWidth
          />
          <ErrorMessage name="name" component={TextError} />
        </div>
        <div className="form-control">
          <Field
            type="email"
            id="email"
            name="email"
            as={TextField}
            variant="outlined"
            color="primary"
            label="E-Mail"
            fullWidth
          />
          <ErrorMessage name="email" component={TextError} />
        </div>

        <div className="form-control">
          <p>Ihr Anliegen</p>
          <div className="form-control-items">
            <FormControlLabel
              name="Probestunde"
              id="Probestunde"
              control={
                <Checkbox type="checkbox" name="checked" value="Probestunde" />
              }
              label="Probestunde"
              labelPlacement="end"
            />
            <FormControlLabel
              name="Teamtraining"
              id="Teamtraining"
              control={
                <Checkbox type="checkbox" name="checked" value="Teamtraining" />
              }
              label="Teamtraining"
              labelPlacement="end"
            />
            <FormControlLabel
              name="Recording"
              id="Recording"
              control={
                <Checkbox type="checkbox" name="checked" value="Recording" />
              }
              label="Recording"
              labelPlacement="end"
            />
          </div>
        </div>

        <div className="form-control">
          <Field
            id="nachricht"
            name="nachricht"
            as={TextField}
            multiline
            variant="outlined"
            color="primary"
            label="Nachricht"
            fullWidth
          />
          <ErrorMessage name="nachricht" component={TextError} />
        </div>
        <div>{result ? <Result /> : null}</div>
        <button className="btn-sideNav" type="submit">
          Senden{' '}
        </button>
      </Form>
    </Formik>
  );
};

export default ContactForm;
