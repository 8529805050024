import Form from '../components/Form';
import React from 'react';
import { AiOutlineContacts } from 'react-icons/ai';


import Seo from '../components/Seo';

const Kontakt = () => {
  return (
    <>
      <Seo title="Kontakt" />

      <div className="card">
        <div className="center">
          <div className="card-top">
            <h1>
              <AiOutlineContacts /> Kontakt
            </h1>
          </div>
          <div className="card-content">
            <Form />
          </div>

        </div>
      </div>
    </>
  );
};

export default Kontakt;
